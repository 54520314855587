"use client";

import { Text } from "@chakra-ui/react";

export function AuthFooter({ children }: { children: React.ReactNode }) {
  return (
    <Text textStyle="sm" color="fg.muted" textAlign="center">
      {children}
    </Text>
  );
}
