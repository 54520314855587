import { Checkbox, type CheckboxProps } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";

export type FormCheckboxProps = Pick<CheckboxProps, "value" | "children"> & {
  isRequired?: boolean;
  isDisabled?: boolean;
  id?: string;
  name: string;
};

export function FormCheckbox<Values>({
  isRequired,
  isDisabled,
  name,
  id = name,
  value,
  ...props
}: FormCheckboxProps) {
  const [field, meta] = useField({ name, type: "checkbox", value });
  const { isSubmitting } = useFormikContext();

  return (
    <Checkbox
      {...props}
      id={id}
      name={name}
      value={value}
      isChecked={field.checked}
      isRequired={isRequired}
      isDisabled={isSubmitting || isDisabled}
      isInvalid={!!meta.error && !!meta.touched}
      onChange={field.onChange}
      onBlur={field.onBlur}
    />
  );
}
