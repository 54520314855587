import Link, { type LinkProps } from "next/link";
import {
  Link as ChakraLink,
  type LinkProps as ChakraLinkProps,
} from "@chakra-ui/next-js";
import { usePathname } from "next/navigation";
import React, { useCallback } from "react";
import { usePageProgress } from "./page-progress";

export type PageLinkProps =
  | (LinkProps & { children?: React.ReactNode; chakra?: false })
  | (ChakraLinkProps & { chakra: true });

export const PageLink = React.forwardRef(
  (
    { onClick: onClickProp, chakra, ...props }: PageLinkProps,
    ref: React.Ref<HTMLAnchorElement>
  ) => {
    const pathname = usePathname();
    const { navigate } = usePageProgress();

    const onClick = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => {
      onClickProp?.(event);

      if (event.isDefaultPrevented()) {
        return;
      }

      const isModifiedEvent = event.metaKey || event.ctrlKey;
      const isOpenInNewTab = event.button === 1 || isModifiedEvent;

      const targetPathname = new URL(props.href as string, window.location.href)
        .pathname;
      const willNavigate = !isOpenInNewTab && pathname !== targetPathname;

      if (willNavigate) {
        navigate();
      }
    }, [onClickProp, navigate, props.href, pathname]);

    if (chakra) {
      return <ChakraLink {...props as ChakraLinkProps} onClick={onClick} ref={ref} />;
    } else {
      return <Link {...props as LinkProps} onClick={onClick} ref={ref} />;
    }
  }
);
