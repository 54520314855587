"use client";

import { AuthFooter } from "../../_components/footer";
import { PageLink } from "@/components/page-link";

export function LoginFooter() {
  return (
    <AuthFooter>
      {"Don't have an account? "}
      <PageLink href="/register" chakra>
        Sign up
      </PageLink>
    </AuthFooter>
  );
}
