"use client";

import Logo from "@/../public/images/icon.svg";
import { Flex, Heading, Icon, Stack, Text } from "@chakra-ui/react";
import Link from "next/link";

export function AuthHeader({
  title,
  description,
  children,
}: {
  title?: string;
  description?: string;
  children?: React.ReactNode;
}) {
  return (
    <Stack spacing="6" textAlign="center">
      <Flex justify="center">
        <Link href="/">
          <Icon as={Logo} color="accent" height="12" width="auto" />
        </Link>
      </Flex>
      <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
        {title && <Heading size={{ base: "xs", md: "sm" }}>{title}</Heading>}
        {description && <Text color="fg.muted">{description}</Text>}
        {children}
      </Stack>
    </Stack>
  );
}
