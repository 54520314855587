"use client";

import { Form } from "@/components/form";
import { FormCheckbox } from "@/components/form/controls/checkbox";
import { FormInput } from "@/components/form/controls/input";
import { SubmitButton } from "@/components/form/submit-button";
import { Button, HStack, Stack } from "@chakra-ui/react";
import { AuthBody } from "../../_components/body";
import { login } from "../_lib/actions";
import { schema } from "../_lib/schema";
import { PageLink } from "@/components/page-link";

export function LoginForm({ redirectTo }: { redirectTo?: string }) {
  return (
    <Form
      schema={schema}
      initialValues={{
        email: "",
        password: "",
        rememberMe: true,
        redirectTo,
      }}
      onSubmit={login}
      enableToasts
      enableCaptcha
    >
      <AuthBody>
        <Stack spacing="5">
          <FormInput
            name="email"
            label="Email"
            type="type"
            isRequired
            placeholder="Enter your email"
          />
          <FormInput
            name="password"
            label="Password"
            type="password"
            isRequired
            placeholder="********"
          />
        </Stack>
        <HStack justify="space-between">
          <FormCheckbox name="rememberMe">Remember me</FormCheckbox>
          <PageLink href="/forgot-password">
            <Button as="div" variant="text" size="sm">
              Forgot password
            </Button>
          </PageLink>
        </HStack>
        <SubmitButton loadingText="Signing in...">Sign in</SubmitButton>
      </AuthBody>
    </Form>
  );
}
